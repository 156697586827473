@import '@styles/colors';
@import '@styles/mixins';
@import '@styles/fonts';
@import '@styles/spacing';

$font-size-error-text-sm: 1.25rem;

.errorImage {
  @extend .mv56;
  object-fit: contain;
  width: 100%;
}

.errorText {
  @extend .mh32;
  font-size: $font-size-error-text-sm;
  color: $red-dark;
  font-weight: $font-weight-light;
  font-family: $font-open-sans-family;
}

.errorContainer {
  @extend .pt144;
}

.errorContainerNoMargin {
  @extend .mt72;
  width: calc(100% + 80px);
  margin-left: -40px;
  margin-right: -40px;
}

.errorMessage {
  @extend .ph32, .pv16;
  background: $white;
}
