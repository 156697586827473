.loadingContainer {
  height: 100vh;
}

.logoLoading {
  @extend .pulsate-bck;
  width: 100px;
  height: 100px;
}

.pulsate-bck {
  -webkit-animation: pulsate-bck 2.5s ease-in-out infinite both;
  animation: pulsate-bck 2.5s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-5 15:5:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
