@import '@styles/spacing';
@import '@styles/shared';
@import './VersionBanner/style.module.scss';

$drawerWidth: 240px;

.content {
  background-color: $white;
  padding-top: $contentPadding;
  margin-left: $drawerWidth;
}

.navigationDrawer > div {
  width: $drawerWidth;
  align-items: center;
  border: none;
}

.navigationDrawerWithVersionBanner > div {
  width: $drawerWidth;
  align-items: center;
  border: none;
  margin-top: $versionBannerHeight;
  height: calc(100vh - #{$versionBannerHeight});
}

.contentWithVersionBanner {
  @extend .content;
  margin-top: $versionBannerHeight;
  height: calc(100vh - #{$versionBannerHeight} - #{$contentPadding});
}

.contentWithoutVersionBanner {
  @extend .content;
  height: calc(100vh - #{$contentPadding});
}
