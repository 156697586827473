@import '@styles/spacing';
@import '@styles/sizing';
@import '@styles/shared';

.backIcon {
  svg {
    display: block;
  }
}

.header {
  padding-left: $contentPadding;
  padding-right: $contentPadding;
}

.pageSubtitle {
  @extend .mt8;
}

.layoutTitleSkeleton {
  width: 250px;
  height: 32px;
}

.topLeftElements {
  display: flex;
  flex-direction: column;
  min-width: 200px;
}

.backLink {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: nowrap;
}
