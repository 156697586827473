$sizes: (
  10: 10%,
  20: 20%,
  30: 30%,
  35: 35%,
  40: 40%,
  50: 50%,
  60: 60%,
  65: 65%,
  70: 70%,
  80: 80%,
  90: 90%,
  100: 100%,
);

@mixin size-modifiers($attributes) {
  @each $name, $size in $sizes {
    &#{$name} {
      @each $attribute in $attributes {
        #{$attribute}: $size;
      }
    }
  }
}

.w {
  @include size-modifiers('width');
}

.h {
  @include size-modifiers('height');
}
