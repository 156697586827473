$body-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$code-font: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

body {
  margin: 0;
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: $code-font;
}

// Some MUI elements seem to not follow the main font family and/or sizes. Force them to do so
.MuiTooltip-tooltip,
.MuiChip-label {
  font-family: $body-font !important;
}

.MuiFormControl-root > label {
  font-family: $body-font;
  font-size: inherit !important;
}
