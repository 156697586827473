@import './spacing';
@import './colors';

$contentPadding: 40px;
$scrollBarWidth: 15px;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  overflow-y: auto;
  background: $grey-pale;
}

.table {
  th,
  td {
    &:first-child {
      padding-left: $contentPadding;
    }
    &:last-child {
      padding-right: $contentPadding;
    }
  }

  tbody.loading {
    @keyframes pulse {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.25;
      }
      100% {
        opacity: 1;
      }
    }

    animation: pulse 1500ms ease infinite;
  }
}

.stripedTable {
  @extend .table;
  tbody > tr:nth-child(2n + 1) {
    background-color: $grey-palest;
  }
}

.minWidthTableCell {
  width: 1px;
  white-space: nowrap;
}

.wordBreakTableCell {
  white-space: normal;
  word-break: break-all;
}

.alignToTopTableCell {
  vertical-align: top;
}

.MuiTableRow-root {
  border-bottom: 1px solid $grey-lighter;

  &:last-child {
    border-bottom: none;
  }
}
.MuiTableCell-root {
  @extend .pl8;
  border-bottom: none;
}
.MuiTableCell-head {
  @extend .pv4;
}

.MuiButton-label {
  white-space: nowrap;
}

.MuiStepper-root {
  @extend .p0;
}

// for markdown editor:
.rc-md-editor {
  border: none !important;
  height: 100% !important;
  min-height: 500px;

  > .rc-md-navigation {
    border: none;

    .button:hover {
      color: $red !important;
    }
  }

  > .editor-container {
    blockquote {
      @extend .mv16, .mh0, .p8, .pl32;
      position: relative;
      background: $grey-pale !important;
      border: none !important;
      border-left: 10px solid $grey-lighter !important;
    }

    table {
      line-height: 1.7 !important;
      max-width: 100% !important;
      overflow: auto !important;
      border: none !important;
      border-collapse: collapse !important;
      border-spacing: 0 !important;
      -webkit-box-sizing: border-box !important;
      box-sizing: border-box !important;
    }

    table td,
    table th {
      @extend .p8;
      word-break: break-all !important;
      word-wrap: break-word !important;
      white-space: normal !important;
      border: none !important;
    }

    table tr {
      border: none !important;
      border-bottom: 1px solid $grey-lighter !important;

      &:last-child {
        border-bottom: none !important;
      }
    }

    table th {
      @extend .pb0;
      text-align: left !important;
      background: none;
      letter-spacing: 0.03333em !important;
      font-weight: 700 !important;
      word-break: break-word !important;
    }

    td {
      text-align: left !important;
      word-break: break-word !important;
      min-width: 60px !important;
    }
  }
}

.MuiStepConnector-root {
  // Fix for CertificationRunsNewRun; StepConnector root class is broken (Material UI bug?)
  padding-bottom: 0px;
}

.noFlexWrap {
  flex-wrap: inherit;
}
