$red-palest: #f9ecec;
$red-pale: #f2dada;
$red-light: #ce3135;
$red: #af1f22;
$red-dark: #991425;
$red-darkest: #7d0f0f;

$white: #fff;

$grey-palest: #fafafa;
$grey-pale: #f5f5f5;
$grey-pale-active: #e0e0e0;
$grey-lighter: #cecece;
$grey-light: #e4eaf2;
$grey: #d3d3d3;
$grey-dark: #979797;
$grey-darkest: #686868;

$black: #000;

// custom flow colors
$green: #159b32;
$orange: #f0ad4e;
$blue: #4682b4;
