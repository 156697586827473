@import '@styles/colors';
@import '@styles/spacing';

$versionBannerHeight: 36px; // also used in BaseLayout component for compensating height

.banner {
  @extend .pv8, .pr40, .pl24;
  background-color: $grey-pale-active;
  z-index: 2;
  border: none;
  font-size: 0.9rem;
  width: 100%;
  height: $versionBannerHeight;
}

.environment {
  color: $red;
  font-weight: bold;
  text-transform: uppercase;
}

.version {
  color: $red;
}

.atriasLink {
  font-size: 0.9rem;
}
