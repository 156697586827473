@import '@styles/spacing';
@import '@styles/colors';
@import '@styles/fonts';
@import '@styles/mixins';

.sidebarContent {
  height: 100%;
}

.atriasLogo {
  @extend .mt32, .mb40;
  width: 80px;

  @include for-xl {
    margin-top: 56px;
    margin-bottom: 72px;
  }
}

.links {
  width: 100%;

  .navLabel {
    @extend .ph24;
  }

  .navRowItem {
    @extend .pv4, .pl24, .mb4;
    width: auto;
    display: block;

    color: $black;
    cursor: pointer;
    font-weight: $font-weight-bold;
    overflow: auto;
    text-decoration: none;
    white-space: nowrap;
    font-size: 1rem;
    font-family: $font-open-sans-family;

    &:hover,
    &:focus {
      color: $red-dark;
      outline: none;
    }
  }

  .navRowItemActive {
    @extend .navRowItem;
    background: $red-pale;
    color: $red-dark;
    border-right: 4px solid $red-dark;
  }

  .navRowSubItemListIcon {
    @extend .mr8;
    color: $red-dark;

    .rowClosed {
      transform: rotate(-90deg);
      transition: 0.25s ease;
    }
    .rowOpen {
      transform: rotate(0deg);
      transition: 0.25s ease;
    }
  }

  .navRowItemSubItem {
    @extend .navRowItem, .pl40;
    font-size: 0.9rem;
  }
}

.bottomItems {
  @extend .p24;
  width: 100%;
  box-sizing: border-box;
}

.questions {
  @extend .p16, .mv32;
  background: $grey-pale;
  border-radius: 4px;

  .topDeskLink {
    font-size: 1rem;

    .forwardIcon {
      svg {
        display: block;
      }
    }
  }
}

.userInfo {
  .role {
    font-size: 0.9rem;
  }

  .logOutButton {
    margin-right: -12px; // compensates for padding of Material UI IconButton
  }
}
